/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/webapp/apps/common/js/jquery/ui/1.10/i18n/jquery.ui.datepicker-pt-BR-aa.js
*    TO   --> ./webapps/shared-web/src/main/assets/js/plugins/date-picker/i18n/jquery.ui.datepicker-pt-BR-aa.js
**/

/* Brazilian initialisation for the jQuery UI date picker plugin. */
/* Written by Leonildo Costa Silva (leocsilva@gmail.com). */
jQuery(function($){
	$.datepicker.regional['pt_BR'] = {
		closeText: 'Fechar',
		prevText: 'Anterior',
		nextText: 'Avan&ccedil;ar',
		currentText: 'Hoje',
		monthNames: ['Janeiro','Fevereiro','Mar&ccedil;o','Abril','Maio','Junho',
		'Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
		monthNamesShort: ['Jan','Fev','Mar','Abr','Mai','Jun',
		'Jul','Ago','Set','Out','Nov','Dez'],
		dayNames: ['Domingo','Segunda','Ter\u00E7a','Quarta','Quinta','Sexta','S\u00E1bado'],
		dayNamesShort: ['Dom','Seg','Ter','Qua','Qui','Sex','S\u00E1b'],
		dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
		weekHeader: 'Sm',
		dateFormat: 'dd/mm/yy',
		firstDay: 0,
		isRTL: false,
		showMonthAfterYear: false,
		yearSuffix: ''};
	$.datepicker.setDefaults($.datepicker.regional['pt_BR']);
});
